import React from "react";
import { Segment, Header } from "semantic-ui-react";
import HeaderComponent from "../components/common/header";

const LookPageBanner = ({ imageSrc, title }) => (
    <Segment
        inverted
        textAlign="center"
        vertical
        style={{
            background: `url(${imageSrc}) no-repeat center center`,
            backgroundSize: "cover",
            height: "350px",
            position: "relative",
        }}
        className="lookPage-banner"
    >
        {/* 遮罩层 */}
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}></div>

        {/* 确保 HeaderComponent 和 Header 在遮罩层之上 */}
        <HeaderComponent page="look" />
        <Header as="h1" content={title} inverted className="custom-header" style={{ position: 'relative', zIndex: 1 }} />
    </Segment>
);

export default LookPageBanner;
