import React, { useEffect } from 'react';
import { graphql } from "gatsby";
import { Image } from "semantic-ui-react";
import Footer from "../components/common/footer";
import LookPageBanner from "./lookPageBanner";
import '../styles/lookPageTemplate.css';
import { Link } from "gatsby";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';  // Import Helmet

const LookPageTemplate = ({ data }) => {
    const { contentfulLooks } = data;

    useEffect(() => {
      AOS.init({
        duration: 2000,
        once: true,
      });
    }, []);

    if (!contentfulLooks) {
        return <p>Loading...</p>;
    }

    return (  
        <>
          <Helmet>
                <title>{contentfulLooks.title} - Series</title>
                <meta name="description" content={`Explore our ${contentfulLooks.title} collection and discover unique series of designs.`} />
            </Helmet>
            <LookPageBanner
                imageSrc={contentfulLooks.bannerImage.fluid.src}
                title={contentfulLooks.title}
            />
            <div className="look-page-container" data-aos="fade-up">
                <div className="series-group">
                    {contentfulLooks.series && contentfulLooks.series.map(series => (
                        <Link to={`/series/${series.slug}`} key={series.id} className="series-item">
                            <div>
                                {series.seriesImage && series.seriesImage.length > 0 && series.seriesImage[0].fluid && (
                                    <Image src={series.seriesImage[0].fluid.src} className="series-image" />
                                )}
                                <div className="series-title">{series.seriesTitle}</div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export const query = graphql`
  query LookPageQuery($slug: String!) {
    contentfulLooks(slug: { eq: $slug }) {
      title
      description
      bannerImage {
        fluid(maxWidth: 4000) {
          src
        }
      }
      series {
        id
        seriesTitle
        slug  
        seriesImage {
          fluid(maxWidth: 4000) {
            src
          }
        }
      }
    }
  }
`;

export default LookPageTemplate;
